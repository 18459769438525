import React from "react";
import Footer from "./common/footer";
import Navbar from "./common/navbar";

export default function Layout({ children }: { children: React.ReactNode }) {
  return (
    <div className="bg-slate-50 min-h-screen min-w-fit">
      <Navbar />
      {children}
      <Footer />
    </div>
  );
}
