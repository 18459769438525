import type { AppProps } from "next/app";
import Script from "next/script";
import { DefaultSeo } from "next-seo";
import { Analytics } from "@vercel/analytics/react";
import { GoogleAnalytics } from "nextjs-google-analytics";
import Layout from "../components/layout";

import "../styles/globals.css";

import SEO from "../next-seo.config.js";

export default function App({ Component, pageProps }: AppProps) {
  return (
    <>
      <GoogleAnalytics trackPageViews />
      <DefaultSeo {...SEO} />
      <Script src="https://challenges.cloudflare.com/turnstile/v0/api.js" />
      <Layout>
        <Component {...pageProps} />
      </Layout>
      <Analytics />
    </>
  );
}
