import Link from "next/link";
import { useRouter } from "next/router";

export default function Navbar() {
  const router = useRouter();

  const createBillingSession = async () => {
    // TODO: add some error handling? maybe a toast? since this is in taskbar

    fetch(`${process.env.NEXT_PUBLIC_API_URL}/billing/portal`, {
      method: "GET",
      credentials: "include",
      //@ts-ignore ???? - these headers are needed for nextjs to pass the cookie to the api
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.error) {
          // TODO: add some error handling? maybe a toast? since this is in taskbar
          // setError(res.error);
        } else {
          // window.location.href = res.url;
          window?.open(res?.url, "_blank")?.focus();
        }
      });
  };

  return (
    <div className="text-lg py-3 flex">
      <div className="flex divide-x items-center">
        <Link
          href="/"
          className={`mx-4 px-4 font-bold border border-2 border-black rounded px-4${
            router.pathname == "/" ? "text-lime-600" : ""
          } hover:bg-lime-600`}
        >
          Handle Grab
        </Link>
        {/* <Link
          href="/twitter"
          className={`px-4 ${
            router.pathname == "/twitter" ? "text-yellow-400" : ""
          } hover:text-yellow-400`}
        >
          Twitter Handle Grab
        </Link> */}
      </div>

      {router.pathname == "/" ? (
        <Link
          href="/login"
          className="absolute right-0 px-8 mr-2 font-bold border border-2 border-black rounded hover:bg-lime-600"
        >
          Login
        </Link>
      ) : null}

      {router.pathname == "/account" ? (
        <button
          onClick={() => createBillingSession()}
          className="absolute right-0 px-8 mr-2 font-bold border border-2 border-black rounded hover:bg-lime-600"
        >
          Billing
        </button>
      ) : null}
    </div>
  );
}
