const config = {
  siteTitle: "HandleGrab", // Site title.
  siteTitleShort: "HandleGrab", // Short site title for homescreen (PWA). Preferably should be under 12 characters to prevent truncation.
  siteTitleAlt:
    "HandleGrab - Grab a username or handle on social media sites as soon as they are available", // Alternative site title for SEO.
  siteLogo: "/logos/logo-1024.webp", // Logo used for SEO and manifest.
  //siteUrl: "https://osrstoolkit.com", // Domain of your website without pathPrefix.
  //siteFullUrl: "https://osrstoolkit.com/", // Domain of your website with pathPrefix.
  siteDescription:
    "HandleGrab - Grab a username or handle on social media sites as soon as they are available.", // Website description used for RSS feeds/meta description tag.
  metaImgUrl: "/favicon.webp", // path to favicon
  //siteFBAppID: "1825356251115265", // FB Application ID for using app insights
  //googleAnalyticsID: "G-7XY3ED9HS0", // GA tracking ID.
  //clarityProjectId: "9pg5pkrbfu", // ID for microsoft clarity
  //disqusShortname: "osrstoolkit", // Disqus shortname.
  dateFromFormat: "YYYY-MM-DD", // Date format used in the frontmatter.
  dateFormat: "DD/MM/YYYY", // Date format for display.
  postsPerPage: 8, // Amount of posts displayed per listing page.
  userLinks: [
    // {
    //   label: "Twitter",
    //   url: "https://twitter.com/osrstoolkit",
    //   iconClassName: "fa fa-twitter",
    // },
    // {
    //   label: "Email",
    //   url: "mailto:hello@osrstoolkit.com",
    //   iconClassName: "fa fa-envelope",
    // },
  ],
  copyright: "Copyright © 2023. HandleGrab", // Copyright string for the footer of the website and RSS feed.
  themeColor: "#c62828", // Used for setting manifest and progress theme colors.
  backgroundColor: "#e0e0e0", // Used for setting manifest background color.
};

export default config;
